import './acceptOfferDefault.scss';
import { Buffer } from 'buffer';
import { useWallet } from '@solana/wallet-adapter-react';
import { VersionedTransaction } from '@solana/web3.js';
import axios from 'axios';
import { FC, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBlock } from '../../ErrorBlock/ErrorBlock';
import { IErrorNames } from '../../ErrorBlock/types';
import { SmallCardInfo } from '../../SmallCardInfo/SmallCardInfo';
import { IAcceptOfferDefaultModalProps } from '../types';
import { Summary } from '@/pages/Checkout';
import {
  createAcceptOfferTxRequest,
  sendAcceptOfferTxsRequest,
} from '@/shared/api/services/listings';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { getRoutePublicAccount } from '@/shared/const/router';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { signTransactionsMethod } from '@/shared/lib/signTransactions/signTransactionsMethod';
import { Alert } from '@/shared/ui/Alert/Alert';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { Spinner } from '@/shared/ui/Loaders';

const AcceptOfferDefault: FC<IAcceptOfferDefaultModalProps> = ({
  getDataCard,
  cardData,
  handleModalClose,
  changeTransactionStatus,
  offer,
  getOffers,
  transactionStatus,
  setTransaction,
  userWallet,
  setisLoading = () => {},
  getNFTCardActivities = () => {},
}) => {
  const { pathname } = useLocation();

  const { signTransaction } = useWallet();
  const { signTrans: signTransactionWeb3 } = useContext(AuthProviderContext);
  const { name, tokenId, frontImage, cardAddress } = cardData;
  const { to, from, buyerExpiry } = offer;
  const [errors, setErrors] = useState<IErrorNames[]>([]);
  const isPendingTransaction = transactionStatus === 'pending';
  const isOwnerError = errors.includes('owner-error');
  const isMEError = errors.includes('magic-eden-error');

  const handeOnErrorModalClose = () => {
    handleModalClose();
    if (pathname.includes('/assets/solana/'))
      getDataCard({ cardAddress: tokenId || '', withLoading: false });
    else getOffers();
  };

  const pollForUpdates = (card: string, interval = 5000) =>
    new Promise<void>(resolve => {
      const intervalId = setInterval(() => {
        if (pathname.includes('/assets/solana/')) {
          getDataCard({ cardAddress: card || '', withLoading: true });
          getNFTCardActivities();
        } else getOffers();
      }, interval);

      setTimeout(() => {
        clearInterval(intervalId);
        resolve();
        setisLoading(false);
      }, interval);
    });

  const acceptOfferFunction = async () => {
    changeTransactionStatus('pending');
    try {
      // const ownerCheckResult = await checkOwner(cardAddress);

      // if (
      //   ownerCheckResult === 'owner-error' ||
      //   ownerCheckResult === 'magic-eden-error'
      // ) {
      //   changeTransactionStatus(ownerCheckResult);
      //   setErrors(prev => [
      //     ...prev.filter(() => !prev.includes(ownerCheckResult)),
      //     ownerCheckResult,
      //   ]);
      //   getDataCard({ cardAddress: cardAddress || '', withLoading: false });
      //   throw new Error(ownerCheckResult);
      // }

      const ut = (await createAcceptOfferTxRequest({
        buyer: offer.from || '',
        buyerExpiry,
        itemName: name,
        nftAddress: cardAddress as string,
        price: offer.price,
      })) as { data: string };
      const transaction = VersionedTransaction.deserialize(
        Buffer.from(ut.data, 'base64'),
      );

      const transactionSigned = await signTransactionsMethod(
        transaction,
        signTransaction,
        signTransactionWeb3,
      );
      if (transactionSigned) {
        const serializedTransactions = Buffer.from(
          transactionSigned!.serialize(),
        ).toString('base64');
        // TODO Delete any when API will finalized
        const transactionSended = (await sendAcceptOfferTxsRequest({
          tx: serializedTransactions,
        })) as {
          data: {
            txId: string;
            txUrl: string;
          };
        };

        setTransaction({
          transactionId: transactionSended.data.txId,
          transactionUrl: transactionSended.data.txUrl,
        });

        if (transactionSigned.signatures.length > 0) {
          changeTransactionStatus('resolved');
          setisLoading(true);
          await pollForUpdates(cardAddress || '');
        }
      }
    } catch (err) {
      changeTransactionStatus('adapter-error');
      setisLoading(true);
      await pollForUpdates(cardAddress || '');
      if (axios.isAxiosError(err)) {
        changeTransactionStatus('axios-error');
        const customError = err.response as IResponse<IErrorMessage>;
        if (customError.status >= 500)
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('unknown-error')),
            'unknown-error',
          ]);

        if (customError?.data?.message.includes('not enough'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('balance-error')),
            'balance-error',
          ]);
      }
    }
  };

  const redirectToProfile = (wallet: string) => {
    if (userWallet === wallet && pathname.includes('/account/'))
      handleModalClose();
    else if (userWallet && userWallet === wallet)
      window.location.href = getRoutePublicAccount(wallet);
    else window.open(`${window.location.origin}/account/${wallet}`, '_blank');
  };

  return (
    <div
      className='accept-offer-modal-default'
      style={{
        minHeight: isOwnerError || isMEError ? 'unset' : '38.875rem',
        paddingTop: isPendingTransaction ? '2rem' : '',
      }}
    >
      <div className='accept-offer-modal-default__close-btn'>
        {!isPendingTransaction && (
          <IconButton size='32' onClick={handleModalClose}>
            <CloseWhiteSVG />
          </IconButton>
        )}
      </div>
      <div className='accept-offer-modal-default__title'>Accept offer</div>
      <div className='accept-offer-modal-default__content'>
        <SmallCardInfo
          name={name}
          tokenId={tokenId}
          image={frontImage}
          isTitleShort={false}
          handleModalClose={handleModalClose}
        />
        <div className='accept-offer-modal-default__content__from-to'>
          <div className='accept-offer-modal-default__content__from-to__item'>
            <span className='accept-offer-modal-default__content__from-to__item__name'>
              From
            </span>
            <span
              className='accept-offer-modal-default__content__from-to__item__value'
              onClick={() => from && redirectToProfile(from)}
            >
              {!!from && limitWordCharacters(from, 4, 'centerDots')}
            </span>
          </div>
          <div className='accept-offer-modal-default__content__from-to__item'>
            <span className='accept-offer-modal-default__content__from-to__item__name'>
              To
            </span>
            <span
              className='accept-offer-modal-default__content__from-to__item__value'
              onClick={() => to && redirectToProfile(to)}
            >
              You
            </span>
          </div>
        </div>
        {isPendingTransaction ? (
          <div className='accept-offer-modal-default__content__confirming'>
            <Spinner size={40} />
            <div className='accept-offer-modal-default__content__confirming__description'>
              <p>Your sale is processing…</p>
              <p>Please wait while the transaction is confirmed</p>
            </div>
          </div>
        ) : (
          <>
            {!!(isOwnerError || isMEError) && (
              <div className='accept-offer-modal-default__content__owner-errors-block'>
                <Alert
                  size='small'
                  text='You are no longer the owner and cannot perform this action !'
                  status='error'
                />
              </div>
            )}
            {!isOwnerError && !isMEError && (
              <div className='accept-offer-modal-default__content__summary'>
                <Summary
                  currency='SOL'
                  typeOfSummary='accept-offer'
                  price={Number(offer.price)}
                  fees={Number(offer.price) * 0.02}
                  title='Summary'
                />
              </div>
            )}
            {!isOwnerError && !isMEError && !!errors.length && (
              <div className='accept-offer-modal-default__content__error-block'>
                {errors.map((error: IErrorNames, index: number) => (
                  <ErrorBlock key={`${index}-${error}`} errorName={error} />
                ))}
              </div>
            )}
            <div
              className='accept-offer-modal-default__content__btn'
              style={{
                paddingTop: isOwnerError || isMEError ? 'none' : '2rem',
              }}
            >
              {isOwnerError || isMEError ? (
                <Button
                  fullWidth
                  typeButton='secondary'
                  text='Close'
                  onClick={handeOnErrorModalClose}
                />
              ) : (
                <Button
                  fullWidth
                  typeButton='white'
                  text='Accept'
                  size='medium'
                  onClick={acceptOfferFunction}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AcceptOfferDefault;
