import { PublicKey, Transaction, VersionedTransaction } from '@solana/web3.js';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface AuthProviderData {
  loginEmailMagicLink?: (email: string) => Promise<void>;
  loginGoogleMagicLink?: () => Promise<void>;
  logoutMagicLink?: () => Promise<void>;
  sendTransaction?: (
    isUSDC: boolean,
    sendAmount: any,
    destinationAddress: PublicKey | string,
  ) => Promise<void>;
  sendingIsLoading?: boolean;
  signTrans?: (
    transactions: Transaction | VersionedTransaction,
  ) => Promise<Transaction | VersionedTransaction>;
  successTrans?: string | null;
  setSuccess?: Dispatch<SetStateAction<string | null>>;
}

export const AuthProviderContext = createContext<AuthProviderData>({});
