import './cancelOfferWarning.scss';
import { Buffer } from 'buffer';
import { useWallet } from '@solana/wallet-adapter-react';
import { VersionedTransaction } from '@solana/web3.js';
import axios from 'axios';
import { FC, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBlock } from '../../ErrorBlock/ErrorBlock';
import { IErrorNames } from '../../ErrorBlock/types';
import CancelOfferOrListingLoading from '../LoadingScreen/LoadingScreen';
import { ICancelDefaultModalProps } from '../types';
import {
  createCancelListingTxRequest,
  createCancelOfferTxRequest,
  sendCancelListingTxsRequest,
  sendCancelOfferTxsRequest,
} from '@/shared/api/services/listings';
import { WarningTriangleSVG } from '@/shared/assets/svg/AlertsIcons';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { signTransactionsMethod } from '@/shared/lib/signTransactions/signTransactionsMethod';
import { Button } from '@/shared/ui/Buttons';

const CancelOfferOrListingDefault: FC<ICancelDefaultModalProps> = ({
  getDataCard,
  handleModalClose,
  subjectId,
  cardData,
  transactionStatus,
  setTransactionStatus,
  getNFTCardActivities = () => {},
  getOffers,
  typeModal,
  setTransaction,
  currentUserWallet,
  setisLoading = () => {},
  setError,
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { signTransaction } = useWallet();
  const { signTrans: signTransactionWeb3 } = useContext(AuthProviderContext);

  const { tokenId, cardAddress, ownerWallet } = cardData;
  const isPendingTransaction = transactionStatus === 'pending';
  const [errors, setErrors] = useState<IErrorNames[]>([]);

  const pollForUpdates = (card: string, interval = 5000) =>
    new Promise<void>(resolve => {
      const intervalId = setInterval(() => {
        if (typeModal === 'listing')
          getDataCard({ cardAddress: card || '', withLoading: true });
        else getOffers();

        getNFTCardActivities();
      }, interval);

      setTimeout(() => {
        clearInterval(intervalId);
        resolve();
        setisLoading(false);
      }, interval);
    });

  const cancelOfferFunction = async () => {
    setTransactionStatus('pending');
    try {
      const createTx = async () => {
        if (typeModal === 'listing') {
          // if ((await checkOwner(cardAddress)) === 'owner-error') {
          //   if (pathname.includes('/assets/solana/'))
          //     getDataCard({
          //       cardAddress: cardAddress || '',
          //       withLoading: false,
          //     });
          //   else
          //     dispatch(
          //       getListings({
          //         params: { wallet: ownerWallet },
          //       }),
          //     );
          //   if (currentUserWallet !== ownerWallet)
          //     throw new Error('owner-error');
          // }
          const createdTx = (await createCancelListingTxRequest({
            coin: 'USDC',
            seller: cardData?.ownerWallet || '',
            tokenMint: cardData.tokenId || '',
          })) as { data: string };

          return createdTx.data;
        }

        if (typeModal === 'offer') {
          const createdTx = (await createCancelOfferTxRequest({
            coin: 'USDC',
            nftAddress: cardAddress || '',
          })) as { data: string };

          return createdTx.data;
        }
      };

      const ut = createTx() as Promise<string>;

      const transaction = VersionedTransaction.deserialize(
        Buffer.from(await ut, 'base64'),
      );

      const transactionSigned = await signTransactionsMethod(
        transaction,
        signTransaction,
        signTransactionWeb3,
      );
      if (transactionSigned) {
        const serializedTransactions = Buffer.from(
          transactionSigned!.serialize(),
        ).toString('base64');

        if (typeModal === 'listing') {
          const transactionSended = (await sendCancelListingTxsRequest({
            tx: serializedTransactions,
          })) as {
            data: {
              txId: string;
              txUrl: string;
            };
          };
          setTransaction({
            transactionId: transactionSended.data.txId,
            transactionUrl: transactionSended.data.txUrl,
          });
        }

        if (typeModal === 'offer') {
          const transactionSended = (await sendCancelOfferTxsRequest({
            tx: serializedTransactions,
          })) as {
            data: {
              txId: string;
              txUrl: string;
            };
          };
          setTransaction({
            transactionId: transactionSended.data.txId,
            transactionUrl: transactionSended.data.txUrl,
          });
        }
        if (transactionSigned.signatures.length > 0) {
          setTransactionStatus('resolved');
          setisLoading(true);
          await pollForUpdates(tokenId || '');
        }
      }
    } catch (err) {
      setisLoading(true);
      setError('error');
      await pollForUpdates(tokenId || '');
      setTransactionStatus('adapter-error');
      if (axios.isAxiosError(err)) {
        setTransactionStatus('axios-error');
        const customError = err.response as IResponse<IErrorMessage>;
        if (customError?.status >= 500)
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('unknown-error')),
            'unknown-error',
          ]);

        if (customError?.data?.message?.includes('not enough'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('balance-error')),
            'balance-error',
          ]);
        if (customError?.data?.message.includes('Expired offer'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('Expired-offer-error')),
            'Expired-offer-error',
          ]);
        if (customError?.data?.message.includes('Invalid ownership'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('Invalid-ownership-error')),
            'Invalid-ownership-error',
          ]);
      }
    }
  };

  return isPendingTransaction ? (
    <CancelOfferOrListingLoading typeModal={typeModal} />
  ) : (
    <div className='cancel-offer-warning'>
      <div className='cancel-offer-warning__icon'>
        <WarningTriangleSVG width='42' height='38' type='alternative' />
      </div>
      <div className='cancel-offer-warning__text-block'>
        <div className='cancel-offer-warning__text-block__title'>
          {` Are you sure want to cancel ${
            typeModal === 'offer' ? 'your offer' : 'the listing'
          }?`}
        </div>
        <div className='cancel-offer-warning__text-block__ordinary'>
          {`This will cancel your ${
            typeModal === 'offer' ? 'offer' : 'listing'
          }. You will also be asked to confirm this
          cancelation from your wallet`}
        </div>
      </div>
      {!!errors.length && (
        <div className='cancel-offer-warning__error-block'>
          {errors.map((error: IErrorNames, index: number) => (
            <ErrorBlock key={`${index}-${error}`} errorName={error} />
          ))}
        </div>
      )}
      <div className='cancel-offer-warning__btns'>
        <Button
          typeButton='secondary'
          text='go back'
          style={{ width: '184px' }}
          onClick={handleModalClose}
        />
        <Button
          typeButton='white'
          text='continue'
          size='medium'
          style={{ width: '184px' }}
          onClick={cancelOfferFunction}
        />
      </div>
    </div>
  );
};

export default CancelOfferOrListingDefault;
