import { Buffer } from 'buffer';
import { useWallet } from '@solana/wallet-adapter-react';
import { Transaction } from '@solana/web3.js';
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import cls from './ListSealedBoxDefault.module.scss';
import { ErrorBlock } from '../../../ErrorBlock/ErrorBlock';
import { SmallCardInfo } from '../../../SmallCardInfo/SmallCardInfo';
import { CurrencyDropdown } from '@/features/CurrencyDropdown';
import {
  getListingFeesRequest,
  createBoxListingRequest,
  sendListingCreateTxsRequest,
} from '@/shared/api/services/listings';
import { InfoCircleSVG } from '@/shared/assets/svg/AlertsIcons';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { classNames } from '@/shared/lib/classNames/classNames';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { convertNumberInK } from '@/shared/lib/helpers/convertNumberInK';
import { useDebounceValue } from '@/shared/lib/hooks/useDebounceValue';
import { signTransactionsMethod } from '@/shared/lib/signTransactions/signTransactionsMethod';
import { Alert } from '@/shared/ui/Alert/Alert';
import { IconButton, Button, IncDecButtons } from '@/shared/ui/Buttons';
import { Htag } from '@/shared/ui/HTag/Htag';
import { Input } from '@/shared/ui/Inputs';
import { Spinner } from '@/shared/ui/Loaders';
import { PtagsName, PtagsValue } from '@/shared/ui/Ptags/Ptags.stories';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Flex } from '@/shared/ui/Stack/Flex/Flex';

export interface ListSealedBoxDefaultProps {
  className?: string;
  transactionStatus: string;
  changeTransactionStatus: (status: string) => void;
  boxData: SealedBox;
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  listingPrice: string;
  setListingPrice: Dispatch<SetStateAction<string>>;
  handleModalClose: () => void;
}

const listCardInputStyles: CSSProperties = {
  alignItems: 'center',
  background: '#121212',
  borderRadius: '0.5rem',
  color: '#E8EAED',
  display: 'flex',
  gap: '0.25rem',
  padding: '0.375rem 0.5rem 0.375rem 0.5rem',
  position: 'absolute',
  right: ' 0.25rem',
  top: '0.7rem',
};

export type IErrorNames =
  | 'unknown-error'
  | 'balance-error'
  | 'owner-error'
  | 'magic-eden-error'
  | 'listing-error'
  | 'price-error';

export const ListSealedBoxDefault = (props: ListSealedBoxDefaultProps) => {
  const {
    className,
    transactionStatus,
    boxData,
    changeTransactionStatus,
    quantity,
    setQuantity,
    listingPrice,
    setListingPrice,
    handleModalClose,
  } = props;
  const { name, address, frontImage, chunkId } = boxData;
  const { signTransaction } = useWallet();
  const { signTrans: signTransactionWeb3 } = useContext(AuthProviderContext);
  const [currency, setCurrency] = useState<ICurrency>('USDC');
  const [errors, setErrors] = useState<IErrorNames[]>([]);
  const [finalReceive, setFinalReceive] = useState<number>(0);
  const [viewFees, setViewFees] = useState(true);

  const debouncedListingPrice = useDebounceValue(listingPrice, 500);

  const isOwnerError = errors.includes('owner-error');
  const isMEError = errors.includes('magic-eden-error');
  const isTransactionPending = transactionStatus === 'pending';
  const isListingPriceCorrect = !!listingPrice && Number(listingPrice) !== 0;

  const handleCurrencyChange = (value: ICurrency) => {
    setCurrency(value);
  };

  const getListingFees = useCallback(async () => {
    if (listingPrice.length) {
      const fees = (await getListingFeesRequest({
        currency,
        nftAddress: chunkId,
        price: Number(listingPrice),
      })) as { data: { fee: number; value: number } };
      const finalReceiveTemp = Number(listingPrice) - Number(fees.data.value);
      setFinalReceive(finalReceiveTemp);
      setViewFees(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedListingPrice]);

  const createBoxListingTx = async () => {
    changeTransactionStatus('pending');
    try {
      const createTx = async () => {
        const createdTx = (await createBoxListingRequest({
          chunkId,
          currency: 'USDC',
          price: Number(listingPrice),
          qty: quantity,
        })) as { data: { ctxId: string; tx: string } };

        return createdTx.data;
      };

      const ut = createTx() as Promise<{ ctxId: string; tx: string }>;

      const transaction = Transaction.from(
        Buffer.from((await ut).tx, 'base64'),
      ) as Transaction;

      const transactionSigned = await signTransactionsMethod(
        transaction,
        signTransaction,
        signTransactionWeb3,
      );
      if (transactionSigned) {
        const serializedTransactions = transactionSigned
          .serialize()
          .toString('base64');

        const TXSended = (await sendListingCreateTxsRequest({
          ctxId: (await ut).ctxId,
          tx: serializedTransactions,
        })) as {
          data: {
            txId: string;
            txUrl: string;
          };
        };
      }
    } catch (err) {
      console.log('createBoxListingTx', err);
    }
  };

  useEffect(() => {
    getListingFees();
  }, [getListingFees]);

  useEffect(() => {
    setViewFees(false);
  }, [listingPrice]);

  return (
    <div className={classNames(cls.SealedBoxListingDefault, {}, [className])}>
      {!isTransactionPending && (
        <div className={cls.closeBtn} onClick={handleModalClose}>
          <IconButton size='32'>
            <CloseWhiteSVG />
          </IconButton>
        </div>
      )}
      <Htag tag='H4-CL' className={cls.titleText}>
        List box for sale
      </Htag>
      <div className={cls.cardPreview}>
        <SmallCardInfo
          name={name}
          tokenId={address}
          image={frontImage}
          isTitleShort={false}
          handleModalClose={handleModalClose}
        />
      </div>
      {isTransactionPending ? (
        <VStack
          gap={1.5}
          justify='center'
          align='center'
          className={cls.loadingTx}
        >
          <Spinner size={40} />
          <PtagsName tag='name'>We are publishing your list...</PtagsName>
        </VStack>
      ) : (
        <VStack>
          <PtagsValue textAlign='left' className={cls.buttonsTitle} tag='value'>
            Quantity
          </PtagsValue>
          <HStack
            justify='start'
            align='center'
            gap={1.438}
            className={cls.incDecButtons}
          >
            <IncDecButtons
              maximumValue={100}
              minimumValue={1}
              quantity={quantity}
              updateQuantity={setQuantity}
            />
            <HStack gap={0.25}>
              <PtagsName tag='name'>Available:</PtagsName>
              <PtagsValue tag='value'>100 boxes</PtagsValue>
            </HStack>
          </HStack>
          <VStack gap={0.375} className={cls.inputBlock}>
            <Input
              isValidate
              type='number'
              label='Price per box'
              value={listingPrice}
              max={999999999}
              placeholder='Enter price'
              onChange={setListingPrice}
            >
              <div>
                <CurrencyDropdown
                  currency={currency}
                  handleCurrencyChange={handleCurrencyChange}
                  styles={listCardInputStyles}
                />
              </div>
            </Input>
          </VStack>
        </VStack>
      )}
      {!!(isOwnerError || isMEError) && (
        <div className={cls.ownerError}>
          <Alert
            size='small'
            status='error'
            text={
              isOwnerError
                ? 'You are no longer the owner and cannot perform this action !'
                : 'This card is listed on another platform, cancel that listing to complete this action!'
            }
          />
        </div>
      )}
      {!isOwnerError && !isMEError && (
        <VStack className={cls.bottomBlock}>
          <HStack
            gap={0.375}
            className={classNames(
              cls.receiveInfo,
              {
                [cls.visible]:
                  !isTransactionPending &&
                  !!finalReceive &&
                  viewFees &&
                  isListingPriceCorrect,
              },
              [],
            )}
          >
            <div>
              <InfoCircleSVG fill='#828585' />
            </div>
            <div className={cls.receiveInfoText}>
              You will receive ${convertNumberInK(finalReceive)} after 2%
              platform fees.
            </div>
          </HStack>
          {!!errors.length && (
            <div className={cls.errorBlock}>
              {errors.map((error: IErrorNames, index: number) => (
                <ErrorBlock key={`${index}-${error}`} errorName={error} />
              ))}
            </div>
          )}
          <Flex direction='row' align='end' className={cls.buttonBlock}>
            {isOwnerError || isMEError ? (
              <Button fullWidth typeButton='secondary' text='Close' />
            ) : (
              <Button
                fullWidth
                typeButton='white'
                text='Complete listing'
                disabled={
                  isTransactionPending ||
                  !listingPrice.length ||
                  Number(listingPrice) === 0
                }
                onClick={createBoxListingTx}
              />
            )}
          </Flex>
        </VStack>
      )}
    </div>
  );
};
