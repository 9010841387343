import { FC, memo, useEffect, useState } from 'react';
import cls from './rotateCard.module.scss';
import { AppImage } from '../AppImage';
import { HStack } from '../Stack';
import { classNames } from '@/shared/lib/classNames/classNames';
import { getCurrentSizeImageInTable } from '@/shared/lib/helpers/getCurrentSizeImageInTable/getCurrentSizeImageInTable';
import { selectBackgroundInStatusCard } from '@/shared/styles/const';

interface IRotateCardProps {
  status: IStatusCards;
  frontImage: string;
  backImage: string;
  type?: string;
  alt?: string;
  orientation: IOrientation;
  inShipment?: boolean;
}

export const RotateCard: FC<IRotateCardProps> = memo(props => {
  const {
    status,
    frontImage,
    backImage,
    alt = '',
    orientation,
    inShipment = false,
    type,
  } = props;

  const [isContain, setIsContain] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);

  const { height, width } = getCurrentSizeImageInTable(
    orientation,
    inShipment ? 'l' : 'xxl',
    type,
  );

  useEffect(() => {
    const img = new Image();
    img.src = frontImage;
    img.onload = () => {
      if (img.width >= 1100 && img.height >= 650) setIsContain(true);
      else setIsContain(false);
    };
  }, [frontImage]);

  const colorBackground = selectBackgroundInStatusCard(status);

  const handleCardClick = () => {
    if (window.innerWidth <= 590) setIsFlipped(prev => !prev);
  };

  return (
    <HStack
      justify='center'
      className={classNames(cls['rotate-card'], { [cls.flipped]: isFlipped }, [
        colorBackground,
      ])}
      onClick={handleCardClick}
    >
      <HStack justify='center' className={cls.front}>
        <AppImage
          src={frontImage}
          alt={alt}
          width={width}
          height={height}
          className={isContain ? cls.contain : cls.cover}
        />
      </HStack>
      <HStack max justify='center' className={cls.back}>
        <AppImage
          src={backImage}
          alt={alt}
          width={width}
          height={height}
          className={isContain ? cls.contain : cls.cover}
        />
      </HStack>
    </HStack>
  );
});
