import { SignerWalletAdapterProps } from '@solana/wallet-adapter-base';
import {
  SignaturePubkeyPair,
  Transaction,
  VersionedTransaction,
} from '@solana/web3.js';

export const signTransactionsMethod = async (
  transaction: Transaction | VersionedTransaction,
  signTransaction: SignerWalletAdapterProps['signTransaction'] | undefined,
  signTransactionMagic?: (
    transaction: Transaction | VersionedTransaction,
  ) => Promise<Transaction | VersionedTransaction>,
): Promise<Transaction | VersionedTransaction | undefined> => {
  if (localStorage.getItem('typeConnect') === 'walletAuth' && signTransaction) {
    const transactionSigned = await signTransaction(transaction as Transaction);
    return transactionSigned;
  }
  if (
    localStorage.getItem('typeConnect') !== 'walletAuth' &&
    signTransactionMagic
  ) {
    if (transaction instanceof Transaction) {
      const signatures: SignaturePubkeyPair[] = transaction.signatures.filter(
        (it): it is SignaturePubkeyPair =>
          'publicKey' in it && it.signature != null,
      );

      const signedTransaction = await signTransactionMagic(transaction);

      signedTransaction.signatures = signedTransaction.signatures.map(
        (item): SignaturePubkeyPair => {
          if ('publicKey' in item) {
            const foundSignature = signatures.find(it =>
              it.publicKey.equals(item.publicKey),
            );
            if (
              item.signature == null &&
              foundSignature &&
              foundSignature.signature != null
            )
              return foundSignature;
          }
          return item as SignaturePubkeyPair;
        },
      );

      return signedTransaction;
    }
    if (transaction instanceof VersionedTransaction) {
      const signedTransaction = await signTransactionMagic(transaction);

      signedTransaction.signatures = signedTransaction.signatures.map(
        (item, index) =>
          item instanceof Uint8Array ? item : transaction.signatures[index],
      );

      return signedTransaction;
    }
  }

  return undefined;
};
